body {
    text-align: center;
    font-size: 1em;
    font-family: verdana;
    margin: 0;
    background-color: black;
    color: #bbb;
}

h1 {
    font-size: 1.2em;
    padding: 20px 0;
    border-bottom: 1px solid #eee;
    margin: 0 0 30px 0;
    color: #b8b8b8;
}

.main-container {
    display: flex;
    width: 100vw;
    justify-content: center;
}


/* rows */

.row {
    text-align: center;
    display: flex;
    justify-content: center;
}

.row>div {
    display: block;
    width: 60px;
    height: 60px;
    border: 1px solid #bbb;
    color: #bbb;
    margin: 4px;
    text-align: center;
    line-height: 60px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 2.5em;
}

@media screen and (max-width: 539px) {
    .row>div {
        width: 80%;
        height: 40px;
        font-size: 1.5em;
        line-height: 40px;
    }
}

.row>div.green {
    --background: #5ac85a;
    --border-color: #5ac85a;
    animation: flip 0.5s ease forwards;
}

.row>div.grey {
    --background: #424242;
    --border-color: #424242;
    animation: flip 0.6s ease forwards;
}

.row>div.yellow {
    --background: #c5a516;
    --border-color: #c5a516;
    animation: flip 0.5s ease forwards;
}

.row>div:nth-child(2) {
    animation-delay: 0.2s;
}

.row>div:nth-child(3) {
    animation-delay: 0.4s;
}

.row>div:nth-child(4) {
    animation-delay: 0.6s;
}

.row>div:nth-child(5) {
    animation-delay: 0.8s;
}

.row>div:nth-child(5) {
    animation-delay: 1s;
}

.row.current>div.filled {
    animation: bounce 0.2s ease-in-out forwards;
}

.keypad {
    max-width: 500px;
    margin: 20px auto;
}

.keypad>div {
    margin: 5px;
    width: 40px;
    height: 50px;
    background: #111;
    color: #bbb;
    display: inline-block;
    border-radius: 6px;
    line-height: 50px;
}

.keypad>div.green {
    background: #5ac85a;
    color: #fff;
    transition: all 0.3s ease-in;
}

.keypad>div.yellow {
    background: #c5a516;
    color: #fff;
    transition: all 0.3s ease-in;
}

.keypad>div.grey {
    background: #424242;
    color: #bbb;
    border-color: #bbb;
    transition: all 0.3s ease-in;
}

.modal {
    background: rgba(43, 43, 43, 0.7);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.modal div {
    max-width: 480px;
    background: #111111;
    padding: 40px;
    border-radius: 10px;
    margin: 10% auto;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
}

.modal .solution {
    color: #00ff4c;
    font-weight: bold;
    font-size: 1.2em;
    text-transform: uppercase;
    letter-spacing: 1px;
}


/* keyframe animations */

@keyframes flip {
    0% {
        transform: rotateX(0);
        background: #fff;
        border-color: #333;
    }
    45% {
        transform: rotateX(90deg);
        background: white;
        border-color: #333;
    }
    55% {
        transform: rotateX(90deg);
        background: var(--background);
        border-color: var(--border-color);
    }
    100% {
        transform: rotateX(0deg);
        background: var(--background);
        border-color: var(--border-color);
        color: #eee;
    }
}

@keyframes bounce {
    0% {
        transform: scale(1);
        border-color: #ddd;
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
        border-color: #888;
    }
}

.toggler {
    border: solid 1px #5ac85a;
}

button.auth {
    color: white;
    background-color: black;
    /* border: solid 1px red; */
    /* border: none; */
    padding: 15px 10px;
    transition: 200ms;
    /* font-size: 130%; */
    font-weight: 700;
}

button.auth.active {
    background-color: #5ac85a;
    color: #111 !important;
}


/* CSS */

.button-37 {
    background-color: #0f1311;
    border: 1px solid #13aa52;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, .1) 0 2px 4px 0;
    box-sizing: border-box;
    color: #13aa52;
    cursor: pointer;
    font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 20px;
    font-weight: 400;
    outline: none;
    outline: 0;
    padding: 10px 25px;
    text-align: center;
    transform: translateY(0);
    transition: transform 150ms, box-shadow 150ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.button-37:hover {
    box-shadow: rgba(0, 0, 0, .15) 0 3px 9px 0;
    transform: translateY(-2px);
}

@media (min-width: 768px) {
    .button-37 {
        padding: 10px 100px;
    }
}